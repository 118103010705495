.Committee {
  width: fit-content;
  height: fit-content;
  padding: 20px 50px;
  margin: 10px;
  border: 1px dotted black;
  cursor: pointer;
}

.OrgDiagram {
  width: 80vw;
  text-align: -webkit-center;
}

.CommitteeTitle {
  padding: 5px 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.selectedCommitteeTitle {
  background: rgb(255 245 161);
}

.ItemTemplate {
  position: absolute;
  box-sizing: content-box;
  border: 1px solid rgb(221, 221, 221);
  background: rgb(238, 238, 238);
  color: rgb(51, 51, 51);
  width: 100%;
  height: 100%;
  left: -1px;
  top: -1px;
}

.ItemTitleBackground {
  position: absolute;
  border-radius: 4px;
  border: 1px solid rgb(221, 221, 221);
  background: rgb(65, 105, 225);
  top: 2px;
  left: 2px;
  right: 2px;
  height: 20px;
}

.selectedItem {
  background: rgb(255 245 161);
}

.ItemTitle {
  left: 2px;
  top: 2px;
  position: relative;
  font-size: 13px;
  color: rgb(255, 255, 255);
}

.ItemPhoto {
  position: absolute;
  box-sizing: content-box;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(246, 246, 246);
  color: rgb(28, 148, 196);
  top: 26px;
  left: 2px;
  width: 60px;
  height: 80px;
}

.ItemDescription {
  position: absolute;
  box-sizing: content-box;
  text-align: left;
  inset: 26px 2px 2px 66px;
  font-size: 12px;
}

.ItemTags {
  position: absolute;
  box-sizing: content-box;
  text-align: left;
  inset: 88px 2px 2px 4px;
  font-size: 15px;
}

.TagSpan {
  margin-right: 4px;
}

.ItemFrame {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid rgb(251, 203, 9);
  background: rgb(253, 245, 206);
  color: rgb(199, 116, 5);
  padding: 3px;
  /* left: -4px;
  top: -4px; */
}

.StyledButton {
  cursor: pointer;
  width: 23px;
  height: 23px;
  font-size: 10px;
  margin-bottom: 3px;
  margin-left: 3px;
  padding: 0;
  border: 1px solid rgb(251, 203, 9);
  background: rgb(253, 245, 206);
}